import Vue from "vue";
import VueRouter from "vue-router";

import { Message } from 'element-ui'

const Login = () => import("../views/LoginView.vue");
const Index = () => import("../views/IndexView.vue");
const Software = () => import("../views/SoftwareView.vue");
const User = () => import("../views/UserView.vue");
const Type = () => import("../views/TypeView.vue");
const File = () => import("../views/FileView.vue");
const Device = () => import("../views/DeviceView.vue");
const UseType = () => import("../views/UseingType.vue");
const Useing = () => import("../views/UseingView.vue");

const StackUser = () => import("../views/StackUser.vue");
const StackDevice = () => import("../views/StackDevice.vue");
const StackConnect = () => import("../views/StackConnect.vue");
const StackFiles = () => import("../views/StackFiles.vue");
const StackRecord = () => import("../views/StackRecord.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: "active",
  routes: [
    { path: "/", redirect: "/login" },
    { path: "/login", component: Login },
    {
      path: "/index", component: Index, children: [
        { path: "", redirect: "software" },
        { path: "software", component: Software },
        { path: "user", component: User },
        { path: "type", component: Type },
        { path: "file", component: File },
        { path: "device", component: Device },
        { path: "usetype", component: UseType },
        { path: "useing", component: Useing },

        { path: "suser", component: StackUser },
        { path: "sdevice", component: StackDevice },
        { path: "sconnect", component: StackConnect },
        { path: "sfiles", component: StackFiles },
        { path: "srecord", component: StackRecord },
      ]
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  let valid = true;
  if (token == null) {
    valid = false;
  }

  // console.log(to.path);

  if (to.path.slice(0, 6) !== "/login" && !valid) {
    next("/login");
    Message({
      showClose: true,
      message: '未检测到登录信息，请先登录',
      type: 'warning'
    });
  }
  next();
});

export default router;
